<template>
  <div class="project-comparison">
    <div class="comparison-list">
      <div
        class="item"
        v-for="item in projectList"
        :key="item.projectId"
      >
        <div class="project-img"><img
            :src="item.projectLogo ? item.projectLogo + '?w=222&h=166' : 'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
            alt=""
          ></div>
        <div class="project-name">{{item.projectName}}</div>
      </div>
      <div
        class="item seat-item"
        v-if="projectList && projectList.length < 2"
      >
        <div class="seat-info"></div>
        <div class="project-name">待添加对比项目</div>
      </div>
      <div
        class="item seat-item"
        v-if="projectList && projectList.length < 3"
      >
        <div class="seat-info"></div>
        <div class="project-name">待添加对比项目</div>
      </div>
      <!-- <div class="item">
        <div class="project-img"><img
            src="http://img3.winshang.com/Upload/project/2017/2/17/131317752759967389.png"
            alt=""
          ></div>
        <div class="project-name">南京金鹰中心</div>
      </div> -->
    </div>
    <div class="select-project">
      <h3 class="w-title-gradients">选择比对项目
        <el-tooltip
          class="item"
          popper-class="planTooltip"
          effect="dark"
          placement="bottom"
        >
          <span>
            <i class="icon-help-tips"></i>
          </span>
          <div slot="content">对比项目将消耗企业的项目对标权益量<br />
            已对比过的项目不会重复扣除</div>
        </el-tooltip>
      </h3>
      <div class="tips-wrap">
        <div class="title">智能推荐逻辑</div>
        <div class="tips">系统将根据当前落位项目的项目类型、开业状态、开业时长、商业面积、项目档次、所属商圈类别，智能推荐经营同品牌年限更久（在营一年以上）且项目属性匹配度较高的项目。您将看到符合该条件且开店时间排名前5的项目</div>
      </div>
    </div>
    <div class="recommend-project">
      <h3 class="w-title-gradients">推荐比对项目</h3>
      <div class="project-list">
        <ul>
          <li
            v-for="item in recommendList"
            :key="item.projectId"
          >
            <div class="project-info">
              <img
                :src="item.projectLogo ? item.projectLogo + '?w=109&h=82' : 'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
                alt=""
              >
              <div class="info">
                <div class="name">{{item.projectName}}</div>
                <div class="status">
                  <span>已留存约 <i>{{item.openDuration}}个月</i></span>
                </div>
              </div>
            </div>
            <div class="address">
              <span>{{item.provinceName}}/{{item.cityName}}</span> <span>{{item.shangquanType}}/{{item.projectGrade}}</span>
            </div>
            <div class="status">
              <span>已开业{{item.kaiyeshichang}}年/</span><span>{{item.shangyeMianji}}㎡</span>
            </div>
            <!-- <div class="label"><span>品牌清单</span></div> -->
            <div
              class="btn"
              @click="isCompartClick(item)"
            >
              <i
                :class="[item.isCompart === 0 ? 'icon-add-project' :'icon-delete-project']"
                class="icon-font"
              ></i> {{item.isCompart === 0 ? '加入对比栏' : '移出对比栏'}}
            </div>
          </li>
        </ul>
      </div>
      <div
        class="no-data"
        v-if="recommendList && recommendList.length === 0"
      >
        <img
          src="@/assets/images/no_list_data.png"
          alt=""
        >
        <p>抱歉，当前模块暂无数据</p>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="lately-project-wrap">
      <h3 class="w-title-gradients">最近比对项目</h3>
      <div class="project-list">
        <ul>
          <li
            v-for="(item, index) in recnetProjectList"
            :key="index"
          >
            <div class="project-info">
              <img
                :src="item.projectLogo ? item.projectLogo + '?w=109&h=82' : 'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
                alt=""
              >
              <div class="info">
                <div class="name">{{item.projectName}}</div>
                <div class="status">
                  <span>已留存约 <i>{{item.openDuration}}个月</i></span>
                </div>
              </div>
            </div>
            <div class="address">
              <span>{{item.provinceName}}/{{item.cityName}}</span> <span>{{item.shangquanType}}/{{item.projectGrade}}</span>
            </div>
            <div class="status">
              <span>已开业{{item.kaiyeshichang}}年/</span><span>{{item.shangyeMianji}}㎡</span>
            </div>
            <!-- <div class="label"><span>品牌清单</span></div> -->
            <div
              class="btn"
              @click="isCompartClick(item)"
            >
              <i
                :class="[item.isCompart === 0 ? 'icon-add-project' :'icon-delete-project']"
                class="icon-font "
              ></i> {{item.isCompart === 0 ? '加入对比栏' : '移出对比栏'}}
            </div>
          </li>
        </ul>
      </div>
      <div
        class="no-data"
        v-if="recnetProjectList && recnetProjectList.length === 0"
      >
        <img
          src="@/assets/images/no_list_data.png"
          alt=""
        >
        <p>抱歉，当前模块暂无数据</p>
      </div>
      <div class="clearfix"></div>
    </div>
    <!-- @isCompartClick="isCompartClick"
      @addCompartClick="addCompartClick" -->
    <comparison-tab
      v-if="filtroStore && projectList && projectList.length > 0"
      @reloadCompartClick="reloadCompartClick"
      :brandId="brandId"
      :filtroProject="filtroProject"
      :filtroStore="filtroStore"
      ref="childMethod"
    ></comparison-tab>
  </div>
</template>

<script>
import api from '@/api'
import comparisonTab from '@/views/storeEarlywarning/projectComparison/components/comparisonTab.vue'
export default {
  name: 'projectComparison',
  data () {
    return {
      projectList: [],
      recommendList: [],
      recnetProjectList: []
    }
  },
  components: {
    comparisonTab
  },
  props: {
    brandId: {
      type: Number
    },
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    }
  },
  mounted () {
    // console.log(this.filtroProject)
    // console.log(this.filtroStore)
    this.init()
  },
  watch: {
    filtroProject (newValue, oldValue) {
      // console.log('filtroProject', this.filtroProject)
    },
    filtroStore (newValue, oldValue) {
      // console.log('filtroStore', this.filtroStore)
    }
  },
  methods: {
    init () {
      const data = {
        brandId: this.brandId,
        projectId: this.filtroProject,
        storeCode: this.filtroStore
      }
      this.axios.post(api.projectRecommend, data)
        .then((res) => {
          this.recommendList = res.data.data
        })
      this.axios.post(api.getTabComprojectList, data)
        .then((res) => {
          this.projectList = res.data.data
          // console.log(this.projectList)
        })
      this.axios.post(api.getRecentProjectList, data)
        .then((res) => {
          this.recnetProjectList = res.data.data
        })
    },
    reloadCompartClick () {
      this.init()
    },
    addCompartClick (item) {
      this.init()
    },
    isCompartClick (item) {
      const data = {
        brandId: this.brandId,
        projectId: this.filtroProject,
        comProjectId: item.projectId
      }
      if (item.isCompart === 0) {
        this.axios.post(api.addCompart, data)
          .then((res) => {
            if (res.data.code === 0) {
              this.init()
              this.$refs.childMethod.init()
            }
          })
      } else {
        this.axios.post(api.deleteCompart, data)
          .then((res) => {
            this.init()
            this.$refs.childMethod.init()
          })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.project-comparison
  margin-right 23px
  padding-bottom 80px
  .comparison-list
    height 300px
    background-color #272930
    border-radius 2px
    display flex
    align-items center
    .item
      flex 1
      text-align center
      .project-img
        width 222px
        height 166px
        margin auto
        img
          width 222px
          height 166px
          object-fit cover
          border-radius 2px
      .project-name
        font-weight bold
        font-size 18px
        color #fff
        margin-top 24px
  .select-project
    margin-top 14px
    background-color #272930
    border-radius 2px
    height 122px
    .tips-wrap
      margin-top 10px
      padding-left 16px
      .title
        font-size 16px
        font-weight bold
        color #fff
        line-height 32px
      .tips
        color #808191
        font-size 14px
  .recommend-project, .lately-project-wrap
    margin-top 14px
    background-color #272930
    border-radius 2px
    padding-bottom 24px
    .project-list
      padding 0 16px
      ul li
        float left
        margin-right 60px
        margin-top 24px
        margin-bottom 24px
        min-width 280px
        height 220px
        &:nth-child(5n)
          margin-right 0
        .project-info
          display flex
          align-items center
          img
            width 109px
            height 82px
            border-radius 2px
            object-fit cover
          .info
            max-width 160px
            margin-left 12px
            .status
              margin-top 14px
              span
                font-size 14px
                font-weight 400
                color #B2B3BD
                i
                  color #FFCE73
        .address
          margin-top 12px
          span
            font-size 14px
            font-weight 400
            color #808191
        .status
          margin-top 6px
          span
            font-size 14px
            font-weight 400
            color #808191
        .label
          margin-top 12px
          span
            background-color #3D3F45
            color #FFFFFF
            font-size 12px
            display inline-block
            padding 3px 8px
            border-radius 13px
        .btn
          cursor pointer
          margin-top 19px
          background-color #1E1E23
          width 130px
          height 34px
          line-height 34px
          text-align center
          font-size 14px
          color #fff
          border-radius 2px
          &:hover
            background-color #F89407
.seat-item
  .seat-info
    width 222px
    height 166px
    border 1px solid #424752
    background-color #373A43
    border-radius 2px
    margin auto
  .project-name
    width 222px
    color #B2B3BD !important
    font-weight bold
    font-size 18px
    margin auto
</style>
